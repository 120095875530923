export const marketing_report_headers = [
    {
        label: 'Campaign Name',
        value: 'campaignName',
        isFixed:true,
        width: 400,
    },
    {
        label: 'UTM Source',
        value: 'utmSource',
        isFixed:false,
        width: 180
    },
    {
        label: 'UTM Medium',
        value: 'utmMedium',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Leads',
        value: 'totalLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Deals',
        value: 'totalDeals',
        isFixed:false,
        width: 150
    },
    {
        label: 'Conversion <br> Ratio (%)',
        value: 'totalDealsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['totalDealsRatio', 'totalDealsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'Amount Spent',
        value: 'amountSpent',
        isFixed:false,
        width: 150
    },
    {
        label: 'Cost Per Lead',
        value: 'costPerLead',
        isFixed:false,
        width: 150
    },
    {
        label: 'Cost of Leads',
        value: 'costOfLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Cost of Deals',
        value: 'costOfDeals',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Revenue',
        value: 'totalRevenue',
        isFixed:false,
        width: 150
    },
    {
        label: 'Credit Note',
        value: 'creditNotes',
        isFixed:false,
        width: 150
    },
    {
        label: 'Profitability',
        value: 'profitability',
        isFixed:false,
        width: 150
    },
    {
        label: 'ROAS',
        value: 'roas',
        isFixed:false,
        width: 150
    },
    {
        label: 'Avg. Policy <br> Price',
        value: 'avgPolicyPrice',
        isFixed:false,
        width: 150
    },
    {
        label: 'Count of Comp <br> Sold',
        value: 'comprehensiveSold',
        isFixed:false,
        width: 150
    },
    {
        label: 'Count of TPL <br> Sold',
        value: 'tplSold',
        isFixed:false,
        width: 150
    },
    {
        label: 'No of Lost <br> Leads',
        value: 'lostLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Lost Leads <br> Ratio (%)',
        value: 'lostLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['lostLeadsRatio', 'lostLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of WIP <br> Leads (NL/PL)',
        value: 'wipLeads',
        isFixed:false,
        isPopover:true,
        headerTitle: 'WIP Leads',
        headers: ['New Leads', 'Pending Leads'],
        values: ['newLeads', 'pendingLeads'],
        width: 170
    },
    {
        label: 'WIP Leads <br> Ratio (%)',
        value: 'wipLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['wipLeadsRatio', 'wipLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of New <br> Leads (NL/QL)',
        value: 'newLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'New Leads <br> Ratio (%)',
        value: 'newLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['newLeadsRatio', 'newLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of Pending <br> Leads',
        value: 'pendingLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Pending Leads <br> Ratio (%)',
        value: 'pendingLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['pendingLeadsRatio', 'pendingLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of Deleted <br> Leads',
        value: 'deletedLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Deleted Leads <br> Ratio (%)',
        value: 'deletedLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['deletedLeadsRatio', 'deletedLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    }
]


export const renewal_status = [
    {
        label: 'Previous </br>Quote Ref No',
        value: 'quote_ref_no',
        width: 250
    },
    {
        label: 'Customer Id',
        value: 'customer_id',
        width: 200
    },
    {
        label: 'Lead Id',
        value: 'lead_id',
        width: 200
    },
    {
        label: 'Customer',
        value: 'customer_name',
        width: 300
    },
    {
        label: 'Car Details',
        value: 'car_details',
        width: 400
    },
    {
        label: 'Chassis No',
        value: 'chassis_no',
        width: 300
    },
    {
        label: 'Current Lead Status',
        value: 'current_lead_status',
        width: 250
    },
    {
        label: 'Insurance Plan </br>(Provider - Type)',
        value: 'plan',
        width: 300
    },
    {
        label: 'Previous </br>Policy Expiry Date',
        value: 'policy_expiry_date',
        width: 250
    },
    {
        label: 'Policy SA',
        value: 'policy_sales_agent',
        width: 250
    },
    {
        label: 'Lead Sales Agent',
        value: 'lead_sales_agent',
        width: 250
    },
    
]

export const lost_lead = [
    {
        label: 'Customer Id',
        value: 'customer_id',
        width: 150
    },
    {
        label: 'Customer',
        value: 'customer_name',
        width: 250
    },
    {
        label: 'Lead Source',
        value: 'lead_source',
        width: 150
    },
    {
        label: 'Lead Status',
        value: 'lead_status',
        width: 200
    },
    {
        label: 'Sales Agent',
        value: 'agent',
        width: 200
    },
    {
        label: 'Lead Id',
        value: 'lead_id',
        width: 150,
        viewTask:true
    },
    {
        label: 'Lead Lost Date',
        value: 'lead_lost_date',
        width: 200
    },
    {
        label: 'Lead Lost Reason',
        value: 'reason',
        width: 400
    },
    {
        label: 'Notes',
        value: 'notes',
        width: 400
    },
    {
        label: 'Car Details',
        value: 'car_details',
        width: 400
    },
    
    {
        label: 'Created On',
        value: 'created_at',
        width: 200
    },
    {
        label: 'Updated On',
        value: 'updated_at',
        width: 200
    },
]

export const agent_escalation = [
    {
        label: 'Agent',
        value: 'agent_name',
    },
    {
        label: 'Escalation Date',
        value: 'created_at',
    },
    {
        label: 'New',
        value: 'new_count',
    },
    {
        label: 'QL',
        value: 'ql_count',
    },
    {
        label: 'Renewal',
        value: 'renewal_count',
    },
    {
        label: 'LLR',
        value: 'llr_count',
    },
    {
        label: 'Pending',
        value: 'pending_count',
    },
    {
        label: 'Total Count',
        value: 'total_count',
    },
   
]

export const underwriter_escalation = [
    {
        label: 'Underwriter',
        value: 'underwriter_name',
    },
    {
        label: 'Escalation Date',
        value: 'created_at',
    },
    {
        label: 'Assigned Policies',
        value: 'assigned',
    },
    {
        label: 'Cancellation Pending',
        value: 'cancellation_pending',
    },
    {
        label: 'Total Count',
        value: 'total_count',
    },
   
]

export const sales_tracking_report = [
    {
        label: 'Name',
        value: 'name',
        width: 180,
        isFixed: true,
    },
    {
        label: 'Revenue',
        value: 'el_total_revenue',
        width: 150
    },
    {
        label: 'Upto </br>Revenue Target',
        value: 'el_upto_revenue_target',
        width: 180
    },
    {
        label: 'Percentage',
        value: 'el_percentage',
        width: 150
    },
    {
        label: 'Deficit',
        value: 'el_deficit',
        width: 150
    },
    {
        label: 'Rank',
        value: 'el_rank',
        width: 150
    },
    {
        label: 'Total Target',
        value: 'el_total_target',
        width: 150
    },
    {
        label: 'Policies Issued',
        value: 'el_policies_issued',
        width: 150
    },
    {
        label: 'Upto </br>Policy Target',
        value: 'el_upto_policy_target',
        width: 150
    },
    {
        label: 'Policy Target',
        value: 'el_policy_target',
        width: 150
    },
    {
        label: '% of </br>Achievement',
        value: 'el_policy_percentage',
        width: 150
    },
    {
        label: 'Total Required',
        value: 'el_total_required',
        width: 150
    },
    {
        label: 'Per-day Required',
        value: 'el_perday_required',
        width: 150
    },
]

export const sales_team_target = [
    {
        label: 'Name',
        value: 'name',
        width: 180,
        isFixed: true,
    },
    {
        label: 'Total Target',
        value: 'el_total_target',
        width: 150
    },
    {
        label: 'Sales',
        value: 'el_total_revenue',
        width: 150
    },
    {
        label: 'Upto </br>Revenue Target',
        value: 'el_upto_revenue_target',
        width: 180
    },
    {
        label: 'Percentage',
        value: 'el_percentage',
        width: 150
    },
    {
        label: '% of Total</br>Achievement',
        value: 'el_percentage_total_achievement',
        width: 150
    },
    {
        label: 'Per-day Required',
        value: 'el_perday_required',
        width: 150
    },
    {
        label: 'Per-day Earned',
        value: 'el_perday_earned',
        width: 150
    },
    {
        label: 'Earning Rate </br>For the Month',
        value: 'el_earning_rate_for_month',
        width: 150
    },
    {
        label: 'Deficit',
        value: 'el_deficit',
        width: 150
    }
]


export const agent_lead_activity_report = [
    {
        label: 'Customer ID',
        value: 'customer_id',
        isFixed: true,
        width: 120
    },
    {
        label: 'Customer Name',
        value: 'customer_name',
        isFixed: true,
        width: 250
    },
    {
        label: 'Lead ID',
        value: 'lead_id',
        isFixed: true,
        width: 120
    },
    {
        label: 'Lead <br/>Actioned',
        value: 'is_actioned',
        width: 100
    },
    {
        label: 'Sales Agent',
        value: 'sales_agent',
        width: 150
    },
    {
        label: 'Time to First Action',
        value: 'time_to_first_action',
        width: 240
    },
    {
        label: 'Time to Last Action',
        value: 'time_to_last_action',
        width: 240
    },
    {
        label: 'First Activity',
        value: 'first_activity',
        width: 180
    },
    {
        label: 'First Acitivity Note',
        value: 'first_acitvity_agent_notes',
        width: 400
    },
    {
        label: 'Last Activity',
        value: 'last_activity',
        width: 180
    },
    {
        label: 'Last Activity Note',
        value: 'last_acitvity_agent_notes',
        width: 400
    },
    {
        label: 'No. of <br/>Follow Ups',
        value: 'total_followups',
        width: 120
    },
    {
        label: 'Open Task <br/>Due Date',
        value: 'latest_open_task_due_date',
        width: 200
    },
    {
        label: 'Lead Source',
        value: 'lead_source',
        width: 150
    },
    {
        label: 'Lead Status',
        value: 'lead_status_text',
        width: 200
    },
    {
        label: 'Lead Created On',
        value: 'lead_created_on',
        width: 180
    },
   
]

export const round_robin_users_listing = [
    {
        label: 'Username',
        value: 'username'
    },
    {
        label: 'Official Email',
        value: 'official_email'
    },
    {
        label: 'Role',
        value: 'role'
    },
    {
        label: 'Type',
        value: 'skye_type'
    },
    {
        label: 'Updated By <br> Updated On',
        value: 'round_robin_updated_values'
    }
]

export const users = [
    {
        label: 'Name',
        value: 'name'
    },
    {
        label: 'Official Email',
        value: 'official_email'
    },
    {
        label: 'Username',
        value: 'username'
    },
    {
        label: 'Role',
        value: 'role'
    },
    {
        label: 'Status',
        value: 'status'
    },
    {
        label: 'Created On<br/>Updated On',
        value: 'created_updated'
    },
]


export const switch_users = [
    {
        label: 'Name',
        value: 'name',
        width: 150

    },
    {
        label: 'Official Email',
        value: 'official_email',
        width: 250

    },
    {
        label: 'Username',
        value: 'username',
        width: 250

    },
    {
        label: 'Role',
        value: 'role',
        width: 150

    },
    {
        label: 'Type',
        value: 'skye_type'
    },
    {
        label: 'Status',
        value: 'status',
        width: 100

    },
]


export const invoice_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        isPopover: true,
        headers: ['RELATION Quote Ref. No.'],
        values: ['relation_quote_ref_no'],
        width: 280
    },
    {
        label: 'INVOICE_TYPE Status',
        value: 'status_text',
        width: 150
    },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: 200
    },
    {
        label: 'Reference No.',
        value: 'reference_no',
        width: 170
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: 170
    },
    {
        label: 'Invoice Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Invoice Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Policy Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Policy Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount (CURRENCY)',
        value: 'discrepancy_amount',
        width: 160
    },
    {
        label: 'Discrepancy <br />Status',
        value: 'discrepancy_status',
        is_discrepancy: true,
        width: 160
    },
    {
        label: 'Transaction <br />Type / Code',
        value: 'transaction_type_text',
        isPopover: true,
        copyValue: true,
        headers: [''],
        values: ['payment_link'],
        width: 200
    },
    {
        label: 'Split <br />Payment',
        value: 'is_split_payment',
        width: 120
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Approved By &<br />Approved On',
        value: 'approved_by_on',
        width: 200
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Admin <br/>Fee (CURRENCY)',
        value: 'admin_fee_amount',
        width: 130,
    },
    {
        label: 'Service <br/>Fee (CURRENCY)',
        value: 'admin_extra_charges',
        width: 130
    },
]

export const invoice_declined_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        isPopover: true,
        headers: ['RELATION Quote Ref. No.'],
        values: ['relation_quote_ref_no'],
        width: 280
    },
    {
        label: 'INVOICE_TYPE Status',
        value: 'status_text',
        width: 150
    },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: 200
    },
    {
        label: 'Reference No.',
        value: 'reference_no',
        width: 170
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: 170
    },
    {
        label: 'Invoice Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Invoice Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Policy Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Policy Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount (CURRENCY)',
        value: 'discrepancy_amount',
        width: 160
    },
    {
        label: 'Transaction <br />Type / Code',
        value: 'transaction_type_text',
        isPopover: true,
        copyValue: true,
        headers: [''],
        values: ['payment_link'],
        width: 200
    },
    {
        label: 'Split <br />Payment',
        value: 'is_split_payment',
        width: 120
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Declined By &<br />Declined On',
        value: 'decline_by_on',
        width: 200
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Admin <br/>Fee (CURRENCY)',
        value: 'admin_fee_amount',
        width: 130,
    },
    {
        label: 'Service <br/>Fee (CURRENCY)',
        value: 'admin_extra_charges',
        width: 130
    },
]
export const invoice_for_approval_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        isPopover: true,
        headers: ['RELATION Quote Ref. No.'],
        values: ['relation_quote_ref_no'],
        width: 250
    },
    {
        label: 'INVOICE_TYPE Status',
        value_1: 'status',
        value_2: 'status_text',
        hasOptions: true,
        width: 250
    },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: 200
    },
    {
        label: 'Reference No.',
        value: 'reference_no',
        width: 170
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: 170
    },
    {
        label: 'Invoice Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Invoice Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Policy Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Policy Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount (CURRENCY)',
        value: 'discrepancy_amount',
        width: 160
    },
    {
        label: 'Transaction <br />Type / Code',
        value: 'transaction_type_text',
        isPopover: true,
        copyValue: true,
        headers: [''],
        values: ['payment_link'],
        width: 200
    },
    {
        label: 'Split <br />Payment',
        value: 'is_split_payment',
        width: 120
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Admin <br/>Fee (CURRENCY)',
        value: 'admin_fee_amount',
        width: 130,
    },
    {
        label: 'Service <br/>Fee (CURRENCY)',
        value: 'admin_extra_charges',
        width: 130
    },
]


export const invoice_addons   = [
    {
        label: 'Name',
        value: 'name'
    },
    {
        label: 'Amount',
        value: 'amount_original_price'
    }
]